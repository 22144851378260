import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Getting Started",
  "sort": 2,
  "title": "Using OpenID Connect",
  "subtitle": "Learn how Criipto supports the OpenID Connect protocol"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Using OpenID Connect to integrate with Criipto Verify`}</h2>
    <p>{`Criipto Verify is integrated through the authentication API which follows the OpenID Connect and OAuth2 specifications. `}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`Criipto Verify is an OpenID Provider. It supports four different OpenID Connect flows: the OAuth2 `}<strong parentName="p">{`authorization code flow`}</strong>{`, the `}<strong parentName="p">{`PKCE flow`}</strong>{`, the (now deprecated) `}<strong parentName="p">{`implicit flow`}</strong>{`, and the `}<strong parentName="p">{`CIBA flow`}</strong>{`. `}</p>
      <p><strong parentName="p">{`The authorization code flow`}</strong>{` is used for traditional server-based web applications, also referred to as `}<em parentName="p">{`confidential clients`}</em>{`. These applications can securely store a client secret and establish back-channel communication with the Criipto Verify service. This facilitates a secure `}<a parentName="p" {...{
          "href": "#exchange-the-code-for-a-token"
        }}>{`code-for-token exchange`}</a>{` during authentication.`}</p>
      <p><strong parentName="p">{`The PKCE flow`}</strong>{` (pronounced `}<em parentName="p">{`pixy`}</em>{`) can be used by `}<em parentName="p">{`public clients`}</em>{` such as single page applications(SPAs) and native applications that cannot keep a secret. With PKCE flow, a one-time secret is generated and used for the code exchange.`}</p>
      <p>{`The traditional `}<strong parentName="p">{`implicit flow`}</strong>{` returns a token directly in the browser. Support for this flow is being discontinued, although it will continue to function on test domains for the foreseeable future for simpler debugging during development.`}</p>
      <p><strong parentName="p">{`The CIBA flow`}</strong>{` is designed for use cases where the client application isn't directly operated by the end-user but can trigger the authentication process on their behalf. A canonical example is a call center agent verifying a caller's identity.`}</p>
      <p><em parentName="p">{`When implementing Criipto Verify, consider your application's architecture and requirements in order to select and implement the appropriate OpenID Connect flow.`}</em></p>
    </Highlight>
    <p>{`The following sections describe the four flows and introduce the parameters to configure the authentication and subsequent user information retrieval.  `}</p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`Please beware that you don't have to go through the below motions manually. Most often it will be handled by configuring an OpenID Connect package on your platform of choice.`}</p>
    </Highlight>
    <h2>{`Authenticate the User`}</h2>
    <p>{`To begin the login flow, you will need to authenticate the user at the identity source indicated in your request.`}</p>
    <p>{`To authenticate the user, your app must send the user to the `}<em parentName="p">{`OAuth2 authorization endpoint`}</em>{` with the appropriate set of parameters.`}</p>
    <p>{`You can find the URL for the `}<em parentName="p">{`OAuth2 authorization endpoint`}</em>{` in the OpenID Connect Discovery Document exposed on your Criipto Verify Domain:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://YOUR_SUBDOMAIN.criipto.id/.well-known/openid-configuration
`}</code></pre>
    <p>{`The response from this endpoint is a JSON document, with an `}<inlineCode parentName="p">{`authorization_endpoint`}</inlineCode>{` property. The corresponding property value is the URL of the `}<em parentName="p">{`OAuth2 authorization endpoint`}</em>{`.`}</p>
    <h3>{`Example authentication URL`}</h3>
    <p>{`The following initiates the authentication through an OAuth2 authorization request:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://YOUR_SUBDOMAIN.criipto.id/oauth2/authorize?
    response_type=code|id_token&
    client_id=CLIENT_ID&
    redirect_uri=YOUR_RETURN_URL&
    acr_values=CHOSEN_IDENTITY_SERVICE&
    scope=openid&
    state=YOUR_STATE
`}</code></pre>
    <p>{`Note that providing `}<inlineCode parentName="p">{`response_type=code`}</inlineCode>{` specifies that you want either the traditional back-channel `}<em parentName="p">{`authorization code`}</em>{` flow or the `}<em parentName="p">{`PKCE`}</em>{` flow. If you specify `}<inlineCode parentName="p">{`response_type=id_token`}</inlineCode>{` you indicate that you want the `}<em parentName="p">{`implicit flow`}</em>{`. In the implicit flow you receive the issued token in a query parameter on the return URL.`}</p>
    <p>{`If you want to receive the response in another way you must specify the `}<inlineCode parentName="p">{`response_mode`}</inlineCode>{` parameter, see below.`}</p>
    <a name="authorize-request-parameters"></a>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`response_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Denotes the kind of credential that Criipto will return (`}<inlineCode parentName="td">{`code`}</inlineCode>{` or `}<inlineCode parentName="td">{`id_token`}</inlineCode>{`). If you are integrating a traditional server based web application (back-channel flow) or a `}<em parentName="td">{`PKCE-enabled`}</em>{` client, use `}<inlineCode parentName="td">{`code`}</inlineCode>{`. Use `}<inlineCode parentName="td">{`id_token`}</inlineCode>{` for legacy single page applications using a front-channel flow.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your application's Client ID. You can find this value in the Criipto Verify UI in the settings for actual application.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`redirect_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL to which Criipto will redirect the browser after authentication has been completed. The authorization code and the `}<inlineCode parentName="td">{`id_token`}</inlineCode>{` will be available in the `}<inlineCode parentName="td">{`code`}</inlineCode>{` and `}<inlineCode parentName="td">{`id_token`}</inlineCode>{` URL parameter for the back-channel flow and on a URL query parameter for the front-channel flow. This URL must be pre-registered as a valid callback URL in your application settings.`}<br />{` `}<br />{` `}<strong parentName="td">{`Warning:`}</strong>{` Per the `}<a parentName="td" {...{
              "href": "https://tools.ietf.org/html/rfc6749#section-3.1.2"
            }}>{`OAuth 2.0 Specification`}</a>{`, Criipto removes everything after the hash and does `}<em parentName="td">{`not`}</em>{` honor any fragments.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scope`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For applications configured with a `}<inlineCode parentName="td">{`static`}</inlineCode>{` scope strategy, specify `}<inlineCode parentName="td">{`openid`}</inlineCode>{`. This gets you the information configured in the management dashboard for each kind of eID (where applicable).  `}<br />{`  `}<br />{`  For applications configured with a `}<inlineCode parentName="td">{`dynamic`}</inlineCode>{` scope strategy, you must specify scope tokens for the types of data you want, in addition to the `}<inlineCode parentName="td">{`openid`}</inlineCode>{` scope token. Possible values are described in the individual eID articles.`}<br />{` `}<br />{`You can read more about this parameter `}<a parentName="td" {...{
              "href": "#the-scope-parameter"
            }}>{`here`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`acr_values`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifies which eID identity service you want to use. You can only specify one value, and it must identify the exact type of identity service, as some countries have, for example, both a mobile and web based service. Possible values can be found in the `}<a parentName="td" {...{
              "href": "/verify/guides/authorize-url-builder/#auth-methods--acr-values"
            }}>{`authorize request builder`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`response_mode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(optional) Specifies how you want your result delivered via the `}<inlineCode parentName="td">{`redirect_uri`}</inlineCode>{`: Use `}<inlineCode parentName="td">{`query`}</inlineCode>{` to return the `}<inlineCode parentName="td">{`code`}</inlineCode>{`/`}<inlineCode parentName="td">{`id_token`}</inlineCode>{` as a query parameter, `}<inlineCode parentName="td">{`fragment`}</inlineCode>{` to have it delivered on a URL fragment, and finally `}<inlineCode parentName="td">{`form_post`}</inlineCode>{` to have it posted back to your `}<inlineCode parentName="td">{`redirect_uri`}</inlineCode>{`.  `}<br />{`  `}<br />{` Default values are `}<inlineCode parentName="td">{`query`}</inlineCode>{` for `}<inlineCode parentName="td">{`response_type=code`}</inlineCode>{` and `}<inlineCode parentName="td">{`query`}</inlineCode>{` for `}<inlineCode parentName="td">{`response_type=id_token`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`state`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(optional but recommended) An opaque arbitrary alphanumeric string your app adds to the initial request that Criipto includes when redirecting back to your application.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`login_hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(optional) Various use cases leverage this parameter. You can read more about them `}<a parentName="td" {...{
              "href": "#the-loginhint-parameter"
            }}>{`here`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`prompt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(optional) Specifies whether the user will be forced to re-authenticate. Possible values are `}<inlineCode parentName="td">{`none`}</inlineCode>{`, `}<inlineCode parentName="td">{`login`}</inlineCode>{`, `}<inlineCode parentName="td">{`consent`}</inlineCode>{`, and `}<inlineCode parentName="td">{`consent_revoke`}</inlineCode>{`. More information is available in our `}<a parentName="td" {...{
              "href": "/verify/guides/sso/#per-request-options"
            }}>{`SSO guide`}</a>{` and in our `}<a parentName="td" {...{
              "href": "/verify/guides/authorize-url-builder/"
            }}>{`authorize URL builder`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`As an example, your HTML snippet for your authorization URL when adding login to your app might look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a href="https://acme-corp.criipto.id/oauth2/authorize?
  response_type=id_token&
  client_id=urn:debug:jwt.io&
  acr_values=urn:grn:authn:no:bankid&
  redirect_uri=https://jwt.io&
  scope=openid&
  state=etats">
  Sign in with Norwegian BankID
</a>
`}</code></pre>
    <p>{`You can `}<a href="https://acme-corp.criipto.id/oauth2/authorize?response_type=id_token&client_id=urn:debug:jwt.io&acr_values=urn:grn:authn:no:bankid&redirect_uri=https://jwt.io&scope=openid&state=etats" target="_blank">{`try the above URL`}</a>{` right now if you have a `}<a parentName="p" {...{
        "href": "/verify/e-ids/norwegian-bankid/#test-users"
      }}>{`test user for Norwegian BankID`}</a>{`.`}</p>
    <p>{`For more about how to handle the implicit flow, see below. `}</p>
    <h2>{`Authenticate with back-channel code flow`}</h2>
    <h3>{`Example request`}</h3>
    <pre><code parentName="pre" {...{}}>{`GET https://YOUR_SUBDOMAIN.criipto.id/oauth2/authorize?
    response_type=code&
    response_mode=query&
    client_id=CLIENT_ID&
    redirect_uri=YOUR_RETURN_URL&
    acr_values=CHOSEN_IDENTITY_SERVICE&
    scope=openid&
    state=YOUR_STATE
`}</code></pre>
    <h3>{`Example response`}</h3>
    <p>{`For the code flow, when you used `}<inlineCode parentName="p">{`response_type=code`}</inlineCode>{`, you will receive an `}<inlineCode parentName="p">{`HTTP 302`}</inlineCode>{` response which redirects your browser to your specified `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` with the authorization code included at the end of the URL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`HTTP/1.1 302 Found
Location: YOUR_RETURN_URL?code=AUTHORIZATION_CODE&state=YOUR_STATE
`}</code></pre>
    <h4>{`Error response`}</h4>
    <p>{`In case the authentication request fails, you will receive an `}<inlineCode parentName="p">{`HTTP 302`}</inlineCode>{` response which redirects your browser to your specified `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` with error included at the end of the URL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`HTTP/1.1 302 Found
Location: YOUR_RETURN_URL?error=ERROR_CODE&error_description=...&state=YOUR_STATE
`}</code></pre>
    <h3>{`Exchange the code for a token`}</h3>
    <p>{`For the code flow you will need to exchange the returned code for an actual token. This is done by posting the authorization code received from the previous step to the token endpoint.`}</p>
    <p>{`For PKCE-enabled clients, this exchange is based on a one-time secret created by the OIDC library you use to handle the flow, and the exchange will also be handled by the same library.`}</p>
    <p>{`For traditional back-channel flows, note that you must use a HTML-form-style HTTP POST here, and preferably send the credentials in the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` HTTP header.
You `}<strong>{`must also x-www-form-urlencode`}</strong>{` the values of the `}<inlineCode parentName="p">{`CLIENT_ID`}</inlineCode>{` and `}<inlineCode parentName="p">{`CLIENT_SECRET`}</inlineCode>{`, respectively, before constructing the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header in `}<inlineCode parentName="p">{`Basic`}</inlineCode>{` format.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`HTTP POST https://YOUR_SUBDOMAIN.criipto.id/oauth2/token
Content-Type: application/x-www-form-urlencoded
Authorization: Basic BASE64(xWwwFormUrlEncode(CLIENT_ID):xWwwFormUrlEncode(CLIENT_SECRET))

grant_type=authorization_code&code=AUTHORIZATION_CODE&client_id=CLIENT_ID&redirect_uri=YOUR_RETURN_URL
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "#code-exchange-authorization-examples"
      }}>{`See more code exchange authorization examples in various languages.`}</a></p>
    <p><strong parentName="p"><em parentName="strong">{`Note`}</em></strong>{` `}<em parentName="p">{`We do also support receiving the client credentials in the payload, but this usage is discouraged by the OAuth2 specification, and we strongly recommend that you send the credentials in the `}<inlineCode parentName="em">{`Authorization: Basic ...`}</inlineCode>{` HTTP header value as described above.`}</em></p>
    <p>{`The client id and secret are retrieved from the Criipto Verify management UI and the `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` must be exactly the same you used in the authorization request in the previous step.`}</p>
    <Highlight icon="exclamation" warning mdxType="Highlight">
      <p>{`Note that the back-channel exchange of the authorization code requires the use of the client secret, which is basically just a password, and therefore `}<em parentName="p">{`must always`}</em>{` be made via a back-channel - server to server - and never from a public client like a browser or native application. Never include the secret in the frontend code.`}</p>
      <p>{`For PKCE-enabled clients, the secret is generated on-the-fly, and no special handling of it is required by you.`}</p>
    </Highlight>
    <h2>{`Authenticate with PKCE`}</h2>
    <p>{`PKCE allows you to use one-time secrets to perform code exchange.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-auth.js"
      }}>{`@criipto/auth-js`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-verify-react"
      }}>{`@criipto/verify-react`}</a>{` support PKCE.`}</p>
    <h3>{`Example request #example-request-pkce`}</h3>
    <pre><code parentName="pre" {...{}}>{`GET https://YOUR_SUBDOMAIN.criipto.id/oauth2/authorize?
    response_type=code&
    response_mode=query&
    client_id=CLIENT_ID&
    redirect_uri=YOUR_RETURN_URL&
    acr_values=CHOSEN_IDENTITY_SERVICE&
    scope=openid&
    state=YOUR_STATE&
    code_challenge=YOUR_CODE_CHALLENGE&
    code_challenge_method=S256
`}</code></pre>
    <h3>{`Example response #example-response-pkce`}</h3>
    <p>{`For authentication using code flow, you will receive an `}<inlineCode parentName="p">{`HTTP 302`}</inlineCode>{` response which redirects your browser to your specified `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` with the authorization code included at the end of the URL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`HTTP/1.1 302 Found
Location: YOUR_RETURN_URL?code=AUTHORIZATION_CODE&state=YOUR_STATE
`}</code></pre>
    <h3>{`Code exchange #code-exchange-pkce`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`HTTP POST https://YOUR_SUBDOMAIN.criipto.id/oauth2/token
Content-Type: application/x-www-form-urlencoded

grant_type=authorization_code&code=AUTHORIZATION_CODE&client_id=CLIENT_ID&redirect_uri=YOUR_RETURN_URL&code_verifier=YOUR_CODE_VERIFIER
`}</code></pre>
    <h2>{`Authenticate with implicit flow`}</h2>
    <p>{`Implicit flow, which returns an `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` directly in the browser via the `}<inlineCode parentName="p">{`#`}</inlineCode>{` fragment, is supported, but not recommended.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-auth.js"
      }}>{`@criipto/auth-js`}</a>{` supports implicit flow.`}</p>
    <h3>{`Example request #example-request-implicit`}</h3>
    <pre><code parentName="pre" {...{}}>{`GET https://YOUR_SUBDOMAIN.criipto.id/oauth2/authorize?
    response_type=id_token&
    response_mode=fragment&
    client_id=CLIENT_ID&
    redirect_uri=YOUR_RETURN_URL&
    acr_values=CHOSEN_IDENTITY_SERVICE&
    scope=openid&
    state=YOUR_STATE
`}</code></pre>
    <h3>{`Example response #example-response-implicit`}</h3>
    <pre><code parentName="pre" {...{}}>{`HTTP/1.1 302 Found
Location: YOUR_RETURN_URL#id_token=eyJ[...].eyJ[...].Sfl[...]&state=[...]
`}</code></pre>
    <h2>{`Authenticate with CIBA`}</h2>
    <p>{`Client-Initiated Backchannel Authentication (`}<a href="https://openid.net/specs/openid-client-initiated-backchannel-authentication-core-1_0.html" target="_blank">{`CIBA`}</a>{`) is a new authentication flow where the client application initiates the authentication process on behalf of the end-user. `}</p>
    <p>{`The caller provides a hint that triggers an authentication request to the user's authentication device, typically a phone. The user can then approve the request and complete the authentication process as usual. `}</p>
    <p>{`CIBA is an extension to OpenID Connect. However, unlike in other OpenID Connect flows, there is a direct communication between your application and the OpenID Provider (Criipto Verify), without redirects through the user's browser.`}</p>
    <p>{`CIBA supports use cases not covered by other OpenID Connect flows, such as: `}</p>
    <ul>
      <li parentName="ul">{`Confirming user's identity to a call center agent during a phone call or via a chat.`}</li>
      <li parentName="ul">{`Using a smartphone to authorize a payment at a point of sale terminal.`}</li>
      <li parentName="ul">{`Enabling a bank agent to authenticate a customer in a bank branch during a face-to-face interaction.`}</li>
    </ul>
    <p>{`Criipto Verify supports CIBA for the `}<a parentName="p" {...{
        "href": "/verify/e-ids/swedish-bankid/#bankid-phone-authentication"
      }}>{`Swedish BankID Phone Authentication`}</a>{`. `}</p>
    <h3>{`Example request #example-request-ciba`}</h3>
    <p>{`The client application shall make an `}<inlineCode parentName="p">{`HTTP POST`}</inlineCode>{` request to the backchannel authentication endpoint to ask for end-user authentication.
`}<em parentName="p">{`The CIBA flow is only available for confidential clients, and the example below uses `}<a parentName="em" {...{
          "href": "/verify/guides/privatekey-jwt/"
        }}>{`private key JWTs`}</a>{` for client authentication.`}</em>{` `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`HTTP POST https://YOUR_SUBDOMAIN.criipto.id/ciba/bc-authorize
Content-Type: application/x-www-form-urlencoded

scope=openid
&callInitiator=RP
&login_hint=sub:ssn:SSN
&acr_values=urn:grn:authn:se:bankid
&binding_message=BINDING_MESSAGE
&client_assertion_type=urn:ietf:params:oauth:client-assertion-type:jwt-bearer
&client_assertion=YOUR_JWT_ASSERTION
`}</code></pre>
    <h3>{`Example response #example-response-ciba`}</h3>
    <p>{`Successful response will contain the request id, `}<inlineCode parentName="p">{`auth_req_id`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`{ 
  "auth_req_id" : "3857f8ff-21b9-48ae-a732-a3bd8128a7ae",
  "expires_in" : 120 
}
`}</code></pre>
    <h3>{`Obtaining the token`}</h3>
    <p>{`Poll the token endpoint (`}<inlineCode parentName="p">{`/oauth2/token`}</inlineCode>{`) providing the `}<inlineCode parentName="p">{`auth_req_id`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`HTTP POST https://YOUR_DOMAIN.criipto.id/oauth2/token
Content-Type: application/x-www-form-urlencoded

auth_req_id=AUTH_REQ_ID
&grant_type=urn:openid:params:grant-type:ciba
&client_assertion_type=urn:ietf:params:oauth:client-assertion-type:jwt-bearer
&client_assertion=$client_assertion
`}</code></pre>
    <h3>{`Token response`}</h3>
    <p>{`The token will be issued upon successful user identification:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`{
  token_type: 'Bearer',
  expires_in: '120',
  id_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjgyN0Q5QTNFOTg2MTY0OTVBQzZGRTE3MUFFNkRBM0IzQ0ExNDE5MjEifQ.eyJpc3MiOiJodHRwczovL25hdGFsaWEtZGV2LXRlc3QuY3JpaXB0by5pbyIsImF1ZCI6InVybjpjaWJhIiwiaWRlbnRpdHlzY2hlbWUiOiJzZWJhbmtpZCIsImF1dGhlbnRpY2F0aW9udHlwZSI6InVybjpncm46YXV0aG46c2U6YmFua2lkIiwiYXV0aGVudGljYXRpb25tZXRob2QiOiJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YWM6Y2xhc3NlczpTb2Z0d2FyZVBLSSIsImF1dGhlbnRpY2F0aW9uaW5zdGFudCI6IjIwMjQtMDUtMjJUMTA6NDE6NDEuMzYxWiIsIm5hbWVpZGVudGlmaWVyIjoiYmI5YzIzNjRkZmFlNDRmM2JjZmQ5OTkwNTNkOTRmOWUiLCJzdWIiOiJ7YmI5YzIzNjQtZGZhZS00NGYzLWJjZmQtOTk5MDUzZDk0ZjllfSIsInNlc3Npb25pbmRleCI6ImI2NWYxMWI2LWViYTctNDg4Mi05MDBhLTVmMmQ5M2EzOGRiYSIsInNzbiI6IjE5NjgwMjAyMDU3NSIsIm5hbWUiOiJUZXJuZSBQYXVsc2VuIiwiZ2l2ZW5uYW1lIjoiVGVybmUiLCJnaXZlbl9uYW1lIjoiVGVybmUiLCJzdXJuYW1lIjoiUGF1bHNlbiIsImZhbWlseV9uYW1lIjoiUGF1bHNlbiIsImlwYWRkcmVzcyI6IjE4NS4xNTcuMTM0LjEzNCIsImNvdW50cnkiOiJTRSIsImlhdCI6MTcxNjM3NDUwMSwibmJmIjoxNzE2Mzc0NTAxLCJleHAiOjE3MTYzNzU3MDF9.RVQnlukfoH597uXzE1Gays5DElGzAr8xgOmi7ZWppaL3QPGhV4vK2o6qLhxXg_-FKG9xCwHR6gEhnNzWA3W3B6Q2zJeQTYh9okUvTmmhAFIyDL7lEtfWVVKUKvauDisYVZDjAxJQS_1zbgPEi5I-UJ6_kvMGH-wC13MAD2bZGTGR2dR-ZevBUn7plOt0PKXrIZD3vwxDfebTMPQqX_9SNT5F7GLjCcpeVK-T5LOgmUMFcTAbHvNyklqP5ymRHsZLDw_ib4I7ZqODhR-3uISWo1NvG4Y84iBcqv50WRNlmMUm004LfPw1flM5DNsVyUWCqYW8m7eBEwLp5va-6OQG4w',
  access_token: 'cf1ce646-7fbe-4740-9c56-fe3f0891f6c6'
}
`}</code></pre>
    <h2>{`Validate the response`}</h2>
    <p>{`You can now proceed with validating the returned `}<inlineCode parentName="p">{`JWT`}</inlineCode>{` and access the contained end-user information.
The validation step is required - if you do not validate the signature, you cannot trust the contained end-user information.
We strongly recommend that you find a battle-hardened library for you specific platfrom to do this heavy lifting.
Criipto has a list of `}<a parentName="p" {...{
        "href": "/verify/integrations"
      }}>{`integrations`}</a>{` and you can find an extensive list of libraries on `}<a parentName="p" {...{
        "href": "https://jwt.io"
      }}>{`jwt.io`}</a>{` (scroll down to the `}<inlineCode parentName="p">{`Libraries for Token Signing/Verification`}</inlineCode>{` section).`}</p>
    <p>{`You can find more informaiton about JWT validation (including a few code samples) `}<a href="https://www.criipto.com/blog/jwt-validation-guide" target="_blank">{`in our blogpost`}</a>{`. `}</p>
    <h2>{`The 'scope' parameter`}</h2>
    <p>{`You can use the `}<inlineCode parentName="p">{`scope`}</inlineCode>{` query parameter to specify which user data you want on a per-authorize request basis.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`You must explicitly enable this feature `}<a parentName="p" {...{
          "href": "https://dashboard.criipto.com/applications"
        }}>{`per application`}</a>{`.
By default, Criipto Verify uses the configured settings per eID (thus effectively ignoring any other `}<inlineCode parentName="p">{`scope`}</inlineCode>{` values than `}<inlineCode parentName="p">{`openid`}</inlineCode>{`).
Activate the "Enable dynamic scopes" toggle on an application to start using this feature.
Once you do that, Criipto Verify will switch from using the per eID configured scope options to the ones you specify in the authorize request.`}</p>
    </Highlight>
    <h3>{`Anatomy of the scope value`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`scope`}</inlineCode>{` query parameter can contain multiple values. Each value is separated by a single blank character (`}<inlineCode parentName="p">{`' '`}</inlineCode>{` / ASCII `}<inlineCode parentName="p">{`32`}</inlineCode>{` / Unicode `}<inlineCode parentName="p">{`U+0020`}</inlineCode>{` ).`}</p>
    <p>{`The `}<inlineCode parentName="p">{`scope`}</inlineCode>{` value must always contain the value `}<inlineCode parentName="p">{`openid`}</inlineCode>{`, and it may also contain any of the following additional values:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`address`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`email`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`phone`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`birthdate`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ssn`}</inlineCode></li>
    </ul>
    <p>{`You can see which values are supported for the various eIDs in the `}<a parentName="p" {...{
        "href": "/verify/guides/authorize-url-builder/#auth-methods--acr-values"
      }}>{`authorize URL builder`}</a>{` or in the individual eID articles.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`The quirks-mode variant of sending the same instructions via the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` is fully supported.
Consult the `}<a parentName="p" {...{
          "href": "/verify/guides/authorize-url-builder/#auth-methods--acr-values"
        }}>{`authorize URL builder`}</a>{` for details. `}</p>
    </Highlight>
    <h2>{`The 'login_hint' parameter`}</h2>
    <p>{`Just as for `}<a parentName="p" {...{
        "href": "#the-scope-parameter"
      }}>{`the `}<inlineCode parentName="a">{`scope`}</inlineCode>{` parameter`}</a>{`, the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` parameter can contain multiple values. Each value here must also be separated by a single blank character.`}</p>
    <p>{`Criipto Verify supports controlling the runtime behavior in quite a few aspects by values sent in this parameter.
We chose this approach as it is most often possible to send them through intermediaries if your architecture has such components (other OpenID Providers such as Azure AD or Auth0).`}</p>
    <p>{`Also, we use it as a fallback for cases where intermediaries do not let you pass values via otherwise standardized OpenID Connect query parameters (most notably `}<inlineCode parentName="p">{`scope`}</inlineCode>{` and `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{`).`}</p>
    <p>{`Go to these specific use cases where `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` is used to specify behaviour or get around limitations:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/guides/appswitch/"
        }}>{`App switching`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/guides/prefilled-fields/"
        }}>{`Prefilled fields`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/guides/authorize-url-builder"
        }}>{`Quirks mode`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/guides/custom-styling/#dynamic-style-sheets"
        }}>{`'Dynamic' styling`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/e-ids/danish-mitid/#business"
        }}>{`DK MitID for business`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#action-specifiers"
        }}>{`Action specifiers`}</a></li>
    </ul>
    <h3>{`Action specifiers`}</h3>
    <p>{`For DK and the SE another-device flows, you can add the following values to the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` query parameter to change the default wording used during login/signing.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`action:confirm`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`action:accept`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`action:approve`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`action:sign`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`action:login`}</inlineCode>{` (the default, mostly present for completeness)`}</li>
    </ul>
    <p>{`Sending, say, `}<inlineCode parentName="p">{`action:approve`}</inlineCode>{` will change "Login at ..." worded elements to "Approve at ...".`}</p>
    <p><a parentName="p" {...{
        "href": "/verify/guides/authorize-url-builder/?acr_values=urn:grn:authn:dk:mitid:low&action=approve"
      }}>{`Authorize URL builder example`}</a></p>
    <h3>{`End-user confirmation texts (message parameter)`}</h3>
    <p>{`Note that specifically for `}<a parentName="p" {...{
        "href": "/verify/e-ids/danish-mitid"
      }}>{`Danish MitID`}</a>{` and `}<a parentName="p" {...{
        "href": "/verify/e-ids/swedish-bankid"
      }}>{`Swedish BankID`}</a>{`, you may also send a base64-url-encoded message via `}<inlineCode parentName="p">{`message:BASE64URL(...text...)`}</inlineCode>{`, which will be shown to the end user in the app.
For example, if you want to show a `}<em parentName="p">{`Transfer EUR 100 to IBAN DK123456781234`}</em>{` message to the user, add a `}<inlineCode parentName="p">{`message:VHJhbnNmZXIgRVVSIDEwMCB0byBJQkFOIERLMTIzNDU2NzgxMjM0`}</inlineCode>{` value to the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/verify/guides/authorize-url-builder/?acr_values=urn:grn:authn:dk:mitid:low&action=approve&message=Transfer%20EUR%20100%20to%20IBAN%20DK123456781234"
      }}>{`Danish MitID: Authorize URL builder example`}</a></p>
    <p>{`The maximum length of the message for Danish MitID is 130 characters `}<em>{`before base64 encoding`}</em>{` (according to our tests).`}</p>
    <p><a parentName="p" {...{
        "href": "/verify/guides/authorize-url-builder/?acr_values=urn:grn:authn:se:bankid:another-device:qr&action=approve&message=Transfer%20EUR%20100%20to%20IBAN%20DK123456781234"
      }}>{`Swedish BankID: Authorize URL builder example`}</a></p>
    <p>{`The maximum length of the message for Swedish BankID is 1500 characters `}<em>{`after base64 encoding.`}</em></p>
    <h2>{`Code exchange authorization examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`# PHP
"Authorization" => "Basic ".base64_encode(urlencode(CRIIPTO_CLIENT_ID).":".urlencode(CRIIPTO_SECRET))
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Node.js
'Authorization': "Basic " + Buffer.from(\`\${encodeURIComponent(CRIIPTO_CLIENT_ID)}:\${encodeURIComponent(CRIIPTO_SECRET)}\`).toString('base64')

// Javascript
'Authorization': "Basic " + btoa(\`\${encodeURIComponent(CRIIPTO_CLIENT_ID)}:\${encodeURIComponent(CRIIPTO_SECRET)}\`)
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`// C#
using System;
using System.Text;
"Basic " + Convert.ToBase64String(Encoding.ASCII.GetBytes(Uri.EscapeDataString(CRIIPTO_CLIENT_ID) + ":" + Uri.EscapeDataString(CRIIPTO_SECRET)))

// C# - if you have a dependency on System.Web.dll or you are willing to take it
using System;
using System.Text;
using System.Web.Security.AntiXss;
"Basic " + Convert.ToBase64String(Encoding.ASCII.GetBytes(AntiXssEncoder.UrlEncode(CRIIPTO_CLIENT_ID) + ":" + AntiXssEncoder.UrlEncode(CRIIPTO_SECRET)))
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      